import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/"

    return (
        <Layout pageTitle="Members - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/members">Back</Link>
                </Col>
            </Row>
            
            
            <ImageDownload
                root={root}
                name=""
                filename=""
                text=""
                squareHref=""
                horizontalHref=""
                copyHref=""
            />
        </Layout>
    )
}

export default IndexPage
